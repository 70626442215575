import React from "react";

import { isEqual } from "lodash";
import { Control, Controller, useFormState } from "react-hook-form";

import { Input } from "@busbud/horizon";

import { DatePassengerSearchGroup } from "@app/components/search-form/date-passenger-search-group";
import { SearchFormButton } from "@app/components/search-form/search-form-button";
import { useIntlProvider } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-intl-provider";
import { openModal } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-modal-state";
import { useLegacySearchFormPassengers } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-search-state-hooks";
import CalendarModal from "@app/components/search-form-hydrated/calendar-modal";
import PassengersModal from "@app/components/search-form-hydrated/passengers-modal";
import { SearchFormValues } from "@app/components/search-form-hydrated/search-form-hydrated";
import { useLiteAppContext } from "@app/helpers/hooks";
import { getPassengerCountTranslation } from "@app/modules/search/helpers/passenger-input";
import { useDateInputs } from "@app/modules/search/store/hooks/date-inputs";
import { usePassengers } from "@app/modules/search/store/hooks/passengers";
import {
  clickedSearchDate,
  clickedSearchPassenger
} from "@app/tracking/search-tracking";
import { yieldToMain } from "@app/utils/scheduler-yield";

export type CompactConfiguration = Partial<
  Record<"xs" | "sm" | "md" | "lg" | "xl", boolean>
>;

interface SearchFormDatePassengerSubmitSectionProps {
  one_way_only: boolean;
  control: Control<SearchFormValues>;
  search_label: string;
  compact_configuration: CompactConfiguration;
}
export const SearchFormDatePassengerSubmitSection = React.memo(
  ({
    one_way_only,
    control,
    search_label,
    compact_configuration
  }: SearchFormDatePassengerSubmitSectionProps) => {
    const { liteTranslator, tracker } = useLiteAppContext();
    const { getLocalizedDate } = useIntlProvider();
    const { isDirty } = useFormState({ control });

    const passengerInputRef = React.useRef<HTMLInputElement>(null);
    const outboundInputRef = React.useRef<HTMLInputElement>(null);
    const returnInputRef = React.useRef<HTMLInputElement>(null);

    const handleDateClick = React.useCallback(
      async (direction: "outbound" | "return") => {
        await yieldToMain();
        openModal(direction === "outbound" ? "outbound_date" : "return_date");

        tracker?.asyncTrack(clickedSearchDate(direction));
      },
      [tracker]
    );

    const handlePassengerClick = React.useCallback(async () => {
      await yieldToMain();
      openModal("passengers");
      tracker?.asyncTrack(clickedSearchPassenger());
    }, [tracker]);

    const { return_date, outbound_date } = useDateInputs();
    const initial_date_values = React.useRef({
      return_date,
      outbound_date
    });

    const { total_passengers_count } = usePassengers();
    const legacy_passenger_state = useLegacySearchFormPassengers();

    // Dynamic search button: if the form state has changed, highlight the search button
    const initial_legacy_passenger_state = React.useRef(legacy_passenger_state);
    const is_passenger_state_updated =
      initial_legacy_passenger_state.current !== legacy_passenger_state;
    const is_date_state_updated = !isEqual(initial_date_values.current, {
      return_date,
      outbound_date
    });
    const is_form_state_updated: boolean =
      isDirty || is_passenger_state_updated || is_date_state_updated;

    const passengers_count_translation = getPassengerCountTranslation(
      liteTranslator,
      total_passengers_count
    );

    return (
      <DatePassengerSearchGroup
        one_way_only={one_way_only}
        outboundDateInputSection={
          <Controller
            control={control}
            name="outbound_date"
            rules={{ required: true }}
            render={({ field: { value } }) => (
              <>
                <Input
                  id="outbound-date-input"
                  labelText={liteTranslator.t(
                    "!search.input.outbound-date.label"
                  )}
                  ref={outboundInputRef}
                  value={getLocalizedDate(value)}
                  onClick={() => handleDateClick("outbound")}
                  className="no-background-rest h-full w-full"
                  type="text"
                  // An ‘em’ space symbol to prevent the floating label from triggering when the value is not ready.
                  // This solution also addresses the specific behavior of the `:placeholder-shown` pseudo-class in Safari.
                  // (refer to the styles of the `Field` for more details)
                  placeholder=" "
                  isReadOnly
                />
                <CalendarModal
                  direction="outbound"
                  input_element={outboundInputRef}
                  selected_date={value}
                />
              </>
            )}
          />
        }
        returnDateInputSection={
          <Controller
            control={control}
            name="return_date"
            render={({ field: { value } }) => (
              <>
                <Input
                  id="return-date-input"
                  labelText={liteTranslator.t(
                    "!search.input.return-date.label"
                  )}
                  ref={returnInputRef}
                  value={getLocalizedDate(value)}
                  onClick={() => handleDateClick("return")}
                  className="no-background-rest h-full w-full"
                  type="text"
                  placeholder={liteTranslator.t(
                    "!search.input.return-date.placeholder"
                  )}
                  isReadOnly
                />
                <CalendarModal
                  direction="return"
                  input_element={returnInputRef}
                  selected_date={value}
                />
              </>
            )}
          />
        }
        passengerInfoSection={
          <>
            <Input
              id="passenger-input"
              labelText={liteTranslator.t("!landing.input-label.passengers")}
              ref={passengerInputRef}
              onClick={handlePassengerClick}
              className="no-background-rest h-full w-full"
              type="text"
              placeholder={passengers_count_translation}
              aria-label={passengers_count_translation}
              value={passengers_count_translation}
              isReadOnly
            />
            <PassengersModal input_element={passengerInputRef} />
          </>
        }
        searchButtonSection={
          <SearchFormButton
            id="search-submit-button"
            label={search_label}
            compact={compact_configuration}
            isSubmit
            isHighlighted={is_form_state_updated}
          />
        }
      />
    );
  }
);
