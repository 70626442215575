import { AffiliatePartners } from "@app/constants/affiliate-partners";

// Wrapper function to get the environment to avoid ReferenceError: window is not defined in test environment
function getEnvironment() {
  try {
    return window?.BB?.config?.env;
  } catch {
    return null;
  }
}

// To enable the feature to 5%, set the const to 0.05
// To disable the feature, set the const to -1
const EXPEDIA_ACTIVATION_PERCENTAGE = getEnvironment() === "preview" ? 1 : -1;
const ALLOWED_COUNTRIES = ["US", "CA"];

export function getPopUnderAffiliate(): AffiliatePartners {
  const is_allowed_country = ALLOWED_COUNTRIES.includes(
    window.BB.user_country_code
  );
  const random_segment = window.BB.random_segment || 0;

  return random_segment > 0 &&
    random_segment <= EXPEDIA_ACTIVATION_PERCENTAGE &&
    is_allowed_country
    ? AffiliatePartners.EXPEDIA
    : AffiliatePartners.BOOKING_COM;
}
