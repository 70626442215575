import React from "react";

import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  useFormState
} from "react-hook-form";

import { IconArrowLeftRight, UnstyledButton } from "@busbud/horizon";

import { LocationGroup } from "@app/components/search-form/location-group";
import { AutocompleteInputHydrated } from "@app/components/search-form-hydrated/autocomplete-input-hydrated";
import { useSearchStateActions } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-search-state-hooks";
import { getGlobalSearchFormState } from "@app/components/search-form-hydrated/search-form-errors";
import { SearchFormValues } from "@app/components/search-form-hydrated/search-form-hydrated";
import { useLiteAppContext } from "@app/helpers/hooks";
import { clickedReverseCities } from "@app/tracking/search-tracking";
import { yieldToMain } from "@app/utils/scheduler-yield";

interface SearchFormOriginDestinationSectionProps {
  one_way_only: boolean;
  control: Control<SearchFormValues>;
  setValue: UseFormSetValue<SearchFormValues>;
  getValues: UseFormGetValues<SearchFormValues>;
}
export const SearchFormOriginDestinationSection = React.memo(
  ({
    one_way_only,
    control,
    setValue,
    getValues
  }: SearchFormOriginDestinationSectionProps) => {
    const { entity, liteTranslator, features, tracker } = useLiteAppContext();
    const { swapCities } = useSearchStateActions();
    const { errors } = useFormState({ control });

    const handleLocationSwap = React.useCallback(async () => {
      const { origin: origin_value, destination: destination_value } =
        getValues();
      setValue("origin", destination_value);
      setValue("destination", origin_value);
      await yieldToMain();
      swapCities();

      const { origin, destination } = getGlobalSearchFormState();
      tracker?.asyncTrack(clickedReverseCities(origin, destination));
    }, [swapCities, setValue, getValues, tracker]);

    const is_results = !!(entity && entity.type === "results");
    const is_disabled =
      is_results && features.SEARCH_FORM_DISABLE_LOCATION_INPUTS_ON_RESULTS;

    return (
      <LocationGroup
        one_way_only={one_way_only}
        originInputSection={
          <Controller
            control={control}
            name="origin"
            rules={{ required: true }}
            render={({ field }) => (
              <AutocompleteInputHydrated
                type="origin"
                isInvalid={!!errors.origin}
                getValues={getValues}
                isDisabled={is_disabled}
                {...field}
              />
            )}
          />
        }
        swapLocationSection={
          <UnstyledButton
            role="button" // TODO: Remove role after fix in horizon
            id="swap-cities-icon"
            data-testid="swap-cities-button"
            className="border rotate-45 rounded-md border-width-sm border-color-primary bg-color-canvas-primary p-100 active:bg-color-canvas-secondary sm:border-color-static-transparent sm:p-075 sm:hover:border-color-primary"
            ariaLabel={liteTranslator.t("!landing.input-label.swap-locations")}
            onClick={handleLocationSwap}
            isDisabled={is_disabled}
          >
            <IconArrowLeftRight
              size="md"
              className="rotate-45 text-icon-color-primary sm:-rotate-45"
            />
          </UnstyledButton>
        }
        destinationInputSection={
          <Controller
            control={control}
            name="destination"
            rules={{ required: true }}
            render={({ field }) => (
              <AutocompleteInputHydrated
                type="destination"
                isInvalid={!!errors.destination}
                getValues={getValues}
                isDisabled={is_disabled}
                {...field}
              />
            )}
          />
        }
      />
    );
  }
);
