import React, { useCallback, useEffect } from "react";

import { UseFormGetValues } from "react-hook-form";
import { useMergeRefs } from "use-callback-ref";

import { ButtonIcon, IconXMark, Input } from "@busbud/horizon";

import { AutocompleteInputDropdown } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-input-dropdown";
import { AutocompleteInputModal } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-input-modal";
import {
  closeModal,
  getInputRef,
  openModal,
  setInputRef,
  useModalState
} from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-modal-state";
import {
  AutocompletePredictionDataset,
  fallback_autocomplete_data,
  NapiFlexLocationOption,
  useNapiPredictions
} from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import { useRecentSearches } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-recent-searches";
import { useSearchQueryValue } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-search-query-value";
import { SearchFormValues } from "@app/components/search-form-hydrated/search-form-hydrated";
import { useLiteAppContext } from "@app/helpers/hooks";
import { suggestionToPlace } from "@app/modules/search/helpers";
import { useFastClick } from "@app/modules/search/use-fast-click";
import {
  clickedSearchInput,
  selectedDestination,
  selectedOrigin
} from "@app/tracking/search-tracking";
import { SearchLocationType } from "@app/types/search-types";
import { yieldToMain } from "@app/utils/scheduler-yield";

export interface AutocompleteInputHydratedProps
  extends Omit<
    React.ComponentProps<typeof Input>,
    "size" | "type" | "labelText"
  > {
  type: SearchLocationType;
  value: string;
  getValues: UseFormGetValues<SearchFormValues>;
}

export interface AutocompletePredictionDatasetOptions {
  suggestions: AutocompletePredictionDataset;
  recent_searches: AutocompletePredictionDataset;
}

export const AutocompleteInputHydrated = React.forwardRef<
  HTMLInputElement,
  AutocompleteInputHydratedProps
>(
  (
    { type, value, onChange, getValues, onFocus, ...other_props },
    forwardRef
  ) => {
    const { debounced_query, updateQuery, updateOnFocus } =
      useSearchQueryValue(value);
    const {
      data: suggestions_dataset = fallback_autocomplete_data,
      isFetching
    } = useNapiPredictions(type, debounced_query);
    const recent_dataset = useRecentSearches(type, debounced_query);
    const { open_input } = useModalState();
    const is_input_open = open_input === type;
    const { liteTranslator, device, tracker } = useLiteAppContext();
    const is_mobile: boolean = !!device?.is_mobile;
    const is_origin = type === "origin";
    const mobile_input_ref = React.useRef<HTMLInputElement | null>(null);
    const dropdown_ref_callback = useCallback(
      (node: HTMLInputElement | null) => {
        // Open the modal/dropdown if the input is already focused on mount
        if (node && document.activeElement === node) {
          openModal(type);
        }
      },
      [type]
    );

    const autocomplete_dataset_options: AutocompletePredictionDatasetOptions = {
      suggestions: suggestions_dataset,
      recent_searches: recent_dataset
    };

    const translations = is_origin
      ? {
          label: liteTranslator.t("!search.input.origin.label"),
          placeholder: liteTranslator.t("!landing.input-label.origin")
        }
      : {
          label: liteTranslator.t("!search.input.destination.label"),
          placeholder: liteTranslator.t("!landing.input-label.destination")
        };

    const { label, placeholder } = translations;

    const handleMobileFocus = useCallback(
      async (event: MouseEvent | TouchEvent | FocusEvent) => {
        if (!is_mobile) {
          return;
        }
        await yieldToMain();

        if (event.target) {
          updateOnFocus((event.target as HTMLInputElement).value);
        }
        getInputRef(type)?.blur();
        openModal(type);
        setTimeout(() => {
          mobile_input_ref.current?.focus();
        });
        tracker?.asyncTrack(clickedSearchInput(type));
      },
      [type, tracker, updateOnFocus, is_mobile]
    );

    const fastClickRef = useFastClick<HTMLInputElement>(handleMobileFocus);
    const combined_ref = useMergeRefs([
      forwardRef,
      (inputRef: HTMLInputElement | null) => setInputRef(type, inputRef),
      dropdown_ref_callback,
      fastClickRef,
      useCallback(
        (inputRef: HTMLInputElement | null) => {
          if (!is_mobile && is_input_open) {
            inputRef?.focus();
          }
        },
        [is_mobile, is_input_open]
      )
    ]);

    const handleInputFocus = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        onFocus?.(event);
        event.target.select(); // Highlights the input text when the user focuses on it
        updateOnFocus(event.target.value);
        openModal(type);
        tracker?.asyncTrack(clickedSearchInput(type));
      },
      [onFocus, updateOnFocus, type, tracker]
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);
        updateQuery(event.target.value, "typing");
      },
      [onChange, updateQuery]
    );

    const handleOptionFocus = useCallback(
      (option: NapiFlexLocationOption) => {
        onChange?.({
          target: { value: option.label }
        } as React.ChangeEvent<HTMLInputElement>);
        updateQuery(option, "hovered");
      },
      [onChange, updateQuery]
    );

    const handleOptionSelect = React.useCallback(
      (option: NapiFlexLocationOption) => {
        onChange?.({
          target: { value: option.label }
        } as React.ChangeEvent<HTMLInputElement>);
        const place = suggestionToPlace(option.value);
        const trackSelection = is_origin ? selectedOrigin : selectedDestination;
        tracker?.asyncTrack(trackSelection(place));
        closeModal();
        updateQuery(option, "selected");
      },
      [onChange, updateQuery, tracker, is_origin]
    );

    const common_props = {
      placeholder,
      onChange: handleChange,
      autoComplete: "off",
      ...other_props
    } as const;

    const autocomplete_props = {
      autocomplete_dataset_options,
      onFocus: handleInputFocus,
      onOptionFocus: handleOptionFocus,
      onOptionSelect: handleOptionSelect,
      is_fetching: isFetching,
      getValues,
      open: is_input_open,
      onClose: closeModal,
      ...common_props
    } as const;

    // Mobile browsers like Safari may add extra scroll space when an input stays focused.
    // To prevent this, we handle touch outside the input and blur it to dismiss the virtual keyboard
    // and remove unnecessary scroll space.
    useEffect(() => {
      if (!is_mobile || !is_input_open || !mobile_input_ref.current) {
        return;
      }

      const inputElement = mobile_input_ref.current;

      const handleTouchOutside = (event: TouchEvent) => {
        if (inputElement && !inputElement.contains(event.target as Node)) {
          inputElement.blur();
        }
      };

      document.addEventListener("touchstart", handleTouchOutside);

      return () => {
        document.removeEventListener("touchstart", handleTouchOutside);
      };
    }, [is_mobile, is_input_open]);

    if (is_mobile) {
      return (
        <>
          <Input
            ref={combined_ref}
            id={`${type}-city-input`} // Used as target for `SkipLink` in `AboveTheFold`
            className="no-background-rest h-full w-full"
            value={value}
            labelText={label}
            {...common_props}
          />
          <AutocompleteInputModal
            type={type}
            renderHeader={() => (
              <div className="flex gap-100">
                <Input
                  className="grow"
                  ref={mobile_input_ref}
                  id={`${type}-input`}
                  labelText={label}
                  slotEnd={
                    <ButtonIcon className="shrink-0" onClick={closeModal}>
                      <IconXMark size="md" />
                    </ButtonIcon>
                  }
                  {...common_props}
                />
              </div>
            )}
            value={value}
            {...autocomplete_props}
          />
        </>
      );
    }

    return (
      <AutocompleteInputDropdown
        ref={combined_ref}
        type={type}
        labelText={label}
        value={value}
        {...autocomplete_props}
      />
    );
  }
);
